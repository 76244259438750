.react-datepicker__year-dropdown
  .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 2%;
  left: 45%;
  margin: auto;
  color: #635bff;
}

.react-datepicker__year-dropdown::after {
  content: "\25BC";
  display: block;
  position: absolute;
  bottom: 2%;
  left: 45%;
  color: #635bff;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 20px !important;
}
.hfltrs .custom-picker .react-datepicker__year-read-view--down-arrow,
.hfltrs .custom-picker .react-datepicker__month-read-view--down-arrow,
.hfltrs .custom-picker .react-datepicker__month-year-read-view--down-arrow {
  right: -18px !important;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #635bff !important;
}
